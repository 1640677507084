import { Avatar, Box, Button, Modal } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { EmployeeUser } from "../../types";
import { useToast } from "../../context/toast-context";
import { UserService } from "../../services/user-service";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  maxWidth: 1200,
  maxHeight: "80vh",
  overflowY: "auto",
  bgcolor: "#fff",
  border: "1px solid #898989",
  boxShadow: 24,
  borderRadius: 4,
};

interface ViewEmployeesProps {
  branchId: number;
  groupId?: number;
}

export function ViewEmployees({ branchId, groupId }: ViewEmployeesProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [employees, setEmployees] = useState<EmployeeUser[]>([]);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const { showToast } = useToast();
  const userService = useMemo(() => new UserService(), []);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const fetchEmployees = async () => {
      setIsLoading(true);

      try {
        await userService.getEmployees(
          { limit: 100, offset: 0, branchid: branchId, groupid: groupId },
          (data) => {
            if (!signal.aborted) {
              setEmployees(data.users);
            }
          },
          (errorMessage) => {
            if (!signal.aborted) {
              showToast(errorMessage, "error");
            }
          }
        );
      } finally {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      }
    };

    if (isModalOpen) {
      fetchEmployees();
    }
    return () => {
      controller.abort();
    };
  }, [showToast, userService, branchId, isModalOpen, groupId]);

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        sx={{
          padding: "4px 8px",
          background: "#EF0BB8",
          borderRadius: "16px",
          color: "white",
          textTransform: "none",
          fontFamily: "Montserrat",
          fontWeight: 300,
          fontSize: "0.9rem",
        }}
      >
        {t("branches.view.label")}
      </Button>
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby={t("branches.view.title")}
          aria-describedby={t("branches.view.title")}
        >
          <Box sx={style}>
            <div className="bg-gray-light text-center text-xl font-normal text-black py-8 px-2">
              <h1>{t("branches.view.title")}</h1>
            </div>

            <div className="px-4 py-8 flex flex-col gap-8">
              {isLoading && (
                <div className="w-full flex justify-center items-center text-md">
                  <p>{t("loading")}</p>
                </div>
              )}
              {!isLoading && employees.length === 0 && (
                <div className="w-full flex justify-center items-center text-md">
                  <p>{t("branches.view.empty")}</p>
                </div>
              )}
              {!isLoading &&
                employees.length > 0 &&
                employees.map((employee) => {
                  const emtpyGroup =
                    employee.userGroups.length === 0 ||
                    (employee.userGroups.length === 1 &&
                      employee.userGroups[0].id === null);

                  return (
                    <div
                      key={employee.userId}
                      className="flex justify-between items-center w-full"
                    >
                      <div className="flex w-full items-center gap-2">
                        <Avatar
                          sx={{
                            width: "2.5rem",
                            height: "2.5rem",
                          }}
                          src="https://media.istockphoto.com/id/1327592506/vector/default-avatar-photo-placeholder-icon-grey-profile-picture-business-man.jpg?s=612x612&w=0&k=20&c=BpR0FVaEa5F24GIw7K8nMWiiGmbb8qmhfkpXcp1dhQg="
                          alt="Avatar Usuario"
                        />
                        <div className="flex flex-col items-start justify-center">
                          <p className="font-semibold text-sm">
                            {employee.name}
                          </p>
                          <p className="text-xs">{employee.email}</p>
                        </div>
                      </div>

                      {employee.groupsTypes.some(
                        (groupType) =>
                          groupType.typeId === 1 || groupType.typeId !== null
                      ) && (
                        <div className="flex gap-4 items-center">
                          <div
                            className={`inline-flex w-fit whitespace-nowrap items-center rounded-full border px-3 py-1 text-sm ${
                              emtpyGroup && "border-yellow text-yellow"
                            }`}
                          >
                            {!emtpyGroup
                              ? employee.userGroups
                                  .map((group) => group.name)
                                  .join(", ")
                              : t("branches.view.no-group")}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
}
