import { Rating, styled } from "@mui/material";
import Star from "../icons/Star";

const StyledRating = styled(Rating)(() => ({
  "& .MuiRating-iconFilled": {
    color: "#f3c433",
  },
  "& .MuiRating-iconHover": {
    color: "#f3c433",
  },
}));

export function BranchesMetrics() {
  return (
    <ul className="flex gap-4 p-2 h-full">
      {[1, 2, 3, 4, 5, 6].map((item) => (
        <li
          key={item}
          className="flex-shrink-0 w-72  p-2 bg-[#e9ebf2] rounded-md"
        >
          <article className="flex flex-col items-start gap-1">
            <div className="w-full flex items-center justify-stretch text-[#727272]">
              <StyledRating
                name="rating"
                value={3}
                size="small"
                icon={<Star />}
                emptyIcon={<Star />}
                sx={{
                  pointerEvents: "none",
                }}
              />
              <span className="text-xl mx-2">4.8</span>
              <span className="text-sm font-light ">(1232)</span>
            </div>
            <div className="font-bold text-2xl text-[#727272]">$255.190</div>
            <span className="text-[#727272] text-sm ">
              Propina promedio $1860
            </span>
            <div
              role="none"
              className="w-full h-[1px] shrink-0 bg-gray-light mb-2"
            />
            <span className="tracking-wide text-md text-[#727272] font-light">
              SUCURSAL 1
            </span>
          </article>
        </li>
      ))}
    </ul>
  );
}
