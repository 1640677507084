import { DateFilter } from "../components/messages/date-filter";
import { MessageFilter } from "../components/messages/message-filter";
import { MessageItem } from "../components/messages/message-item";
import { CustomCheckbox } from "../components/messages/custom-checkbox";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Rating } from "../types";
import { useToast } from "../context/toast-context";
import { RatingService } from "../services/rating-service";
import { Loading } from "../components/Loading";
import { useInfinityScroll } from "../hooks/useInfinityScroll";
import { BranchData } from "../services/dto/response/ratings-response-dto";
import { MessageActions } from "../components/messages/message-actions";

const ITEMS_PER_PAGE = 25;

export function Messages() {
  const [ratingsInfo, setRatingsInfo] = useState<Rating[]>([]);
  const [branches, setBranches] = useState<BranchData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedMessages, setSelectedMessages] = useState<{
    [id: string]: boolean;
  }>({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedBranch, setSelectedBranch] = useState<number | null>(null);
  const [selectedEmployee, setSelectedEmployee] = useState<number | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);

  const { showToast } = useToast();
  const ratingService = useMemo(() => new RatingService(), []);

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const newSelection = ratingsInfo.reduce((acc, rating) => {
      acc[rating.ratingId] = isChecked;
      return acc;
    }, {} as { [id: string]: boolean });

    setSelectedMessages(newSelection);
  };

  const handleSelectMessage =
    (id: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation(); // Evitamos ejecutar handleOpenMessage al clickear el checkbox
      setSelectedMessages((prev) => ({
        ...prev,
        [id]: e.target.checked,
      }));
    };

  const areAllSelected =
    ratingsInfo.length > 0 &&
    ratingsInfo.every((rating) => selectedMessages[rating.ratingId]);

  const resetFilters = () => {
    setSelectedBranch(null);
  };

  const handleMarkAsRead = async (ids: number[], isRead: boolean) => {
    await ratingService.updateRating(
      { ids, isRead },
      () => {
        setRatingsInfo((prev) =>
          prev.map((rating) =>
            ids.includes(rating.ratingId) ? { ...rating, isRead } : rating
          )
        );
        setSelectedMessages({});
      },
      (error) => showToast(error, "error")
    );
  };

  const handleMessageOpen = async (ratingId: number) => {
    // Solo actualizamos si el mensaje abierto no estaba como leído inicialmente
    if (!ratingsInfo.find((rating) => rating.ratingId === ratingId)?.isRead) {
      await handleMarkAsRead([ratingId], true);
    }
  };

  const selectedIds = Object.entries(selectedMessages)
    .filter(([_, isSelected]) => isSelected)
    .map(([id]) => parseInt(id));

  const selectedRatings = ratingsInfo.filter((rating) =>
    selectedIds.includes(rating.ratingId)
  );
  const hasUnreadSelected = selectedRatings.some((rating) => !rating.isRead);

  const fetchRatings = useCallback(async () => {
    setIsLoading(true);

    try {
      await ratingService.getRatings(
        {
          limit: ITEMS_PER_PAGE,
          offset: page,
          ...(startDate && { fechadesde: startDate }),
          ...(endDate && { fechahasta: endDate }),
          ...(selectedBranch && { branchid: selectedBranch }),
          ...(selectedEmployee && { userid: selectedEmployee }),
        },
        (data) => {
          setRatingsInfo((prev) => {
            if (page === 0) return data.ratings.ratings;

            return [...prev, ...data.ratings.ratings];
          });
          setHasMore(data.ratings.ratings.length === ITEMS_PER_PAGE);
          setBranches(data.branches);
        },
        (errorMessage) => {
          showToast(errorMessage, "error");
        }
      );
    } finally {
      setIsLoading(false);
    }
  }, [
    ratingService,
    showToast,
    startDate,
    endDate,
    selectedBranch,
    page,
    selectedEmployee,
  ]);

  useEffect(() => {
    fetchRatings();
  }, [fetchRatings]);

  // Si cambian filtros reseteamos la pagina
  useEffect(() => {
    setPage(0);
    setRatingsInfo([]);
    setHasMore(true);
  }, [startDate, endDate, selectedBranch]);

  const loadMore = useCallback(() => {
    if (!isLoading && hasMore) {
      setPage((prev) => prev + 1);
    }
  }, [isLoading, hasMore]);

  const lastRatingRef = useInfinityScroll({
    loadMore,
    hasMore,
    loading: isLoading,
  });

  return (
    <main className="h-full flex flex-col rounded-3xl bg-white p-6 overflow-hidden">
      {/* Contenido encabezado */}
      <div className="mb-4 pr-4 flex justify-end">
        <DateFilter
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </div>

      {/* Contenido principal */}
      <div className="flex flex-col flex-1 gap-2 h-full overflow-y-auto custom-scrollbar rounded-lg p-4">
        {isLoading && page === 0 && <Loading />}
        <div className="w-full flex justify-between mb-4 pl-6">
          <div className="flex items-center">
            <CustomCheckbox
              id="select-all"
              name="select-all"
              checked={areAllSelected}
              onChange={handleSelectAll}
            />
            <label
              htmlFor="select-all"
              className="font-semibold text-[#898989] text-sm mx-2"
            >
              Seleccionar todos
            </label>
            {selectedIds.length > 0 && (
              <MessageActions
                hasUnread={hasUnreadSelected}
                onMarkAsRead={() => handleMarkAsRead(selectedIds, true)}
                onMarkAsUnread={() => handleMarkAsRead(selectedIds, false)}
              />
            )}
          </div>
          <div className="flex items-center gap-4">
            {selectedBranch && (
              <button
                className="text-sm p-2 rounded-xl border text-gray-500 hover:text-gray-700 hover:bg-gray-100 transition-all duration-100 ease-in"
                onClick={resetFilters}
              >
                Limpiar filtros
              </button>
            )}
            <MessageFilter
              branches={branches}
              selectedBranch={selectedBranch}
              setSelectedBranch={setSelectedBranch}
              selectedEmployee={selectedEmployee}
              setSelectedEmployee={setSelectedEmployee}
            />
          </div>
        </div>
        {!isLoading && ratingsInfo.length === 0 && (
          <div className="w-full h-full flex justify-center items-center">
            <p className="text-gray-500 text-xl font-medium">Sin mensajes</p>
          </div>
        )}
        {ratingsInfo.length > 0 &&
          ratingsInfo.map((rating, index) => (
            <MessageItem
              key={rating.ratingId}
              rating={rating}
              checked={!!selectedMessages[rating.ratingId]}
              onChange={handleSelectMessage(rating.ratingId)}
              onOpen={() => handleMessageOpen(rating.ratingId)}
              ref={index === ratingsInfo.length - 1 ? lastRatingRef : null}
            />
          ))}
      </div>
    </main>
  );
}
