import Star from "../icons/Star";

export function OverallRating() {
  const items = [
    { label: "Valoraciones", value: "850" },
    {
      label: "Valoración promedio",
      value: "8.5",
    },
    { label: "Propinas recibidas", value: "822" },
    { label: "Propina promedio", value: "$2500" },
    { label: "Total propinas", value: "$100.000" },
  ];
  return (
    <div className="px-4 h-full w-full flex items-center">
      <div className="flex h-full w-full justify-center flex-col items-center">
        <div className="flex justify-between w-full  mb-2">
          {items.map((item, index) => (
            <div key={index} className="text-center w-1/5">
              <div className="text-white text-xs font-semibold tracking-wider">
                {item.label}
              </div>
            </div>
          ))}
        </div>

        {/* Linea horizontal */}
        <div className="relative w-full  flex items-center">
          <div className="border-t-[1px] border-white w-full absolute left-0"></div>
          {items.map((_, index) => (
            <div key={index} className="relative flex-1 flex justify-center">
              {index < items.length - 1 && (
                <div className="border-l-[1px] border-white h-6 absolute right-0 top-[-12px]"></div>
              )}
            </div>
          ))}
        </div>

        <div className="flex justify-between w-full  mt-2">
          {items.map((item, index) => (
            <div key={index} className="text-center w-1/5">
              <div className="flex items-center justify-center ">
                <span className="flex items-center text-white font-bold text-xl">
                  {index === 1 && <Star />}
                  {item.value}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
