interface MessageActionProps {
  hasUnread: boolean;
  onMarkAsRead: () => void;
  onMarkAsUnread: () => void;
}

export function MessageActions({
  hasUnread,
  onMarkAsRead,
  onMarkAsUnread,
}: MessageActionProps) {
  return (
    <button
      onClick={hasUnread ? onMarkAsRead : onMarkAsUnread}
      className="text-sm p-2 rounded-xl border text-gray-500 hover:text-gray-700 hover:bg-gray-100 transition-all duration-100 ease-in"
    >
      {hasUnread ? "Marcar como leído" : "Marcar como no leído"}
    </button>
  );
}
