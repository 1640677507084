import { IconButton } from "@mui/material";

export function ChangeAvatar() {
  return (
    <>
      <IconButton
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          borderRadius: "50%",
          width: "1.5rem",
          height: "1.5rem",
          padding: 0,
          outline: "none",
          border: "none",
        }}
        aria-label="Cambiar foto"
      >
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="10" fill="#EF0BB8" />
          <path
            d="M12 7V17M7 12H17"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </IconButton>
    </>
  );
}
