import { Branch } from "../types";
import BaseService from "./base-service";
import {
  BranchesInformationReq,
  BranchesReqDTO,
  UpdatedBranch,
} from "./dto/request/branches-request-dto";
import { RequestDto } from "./dto/request/request-dto";
import {
  AddBranchDTO,
  BranchesInformationRes,
  GetBranchesResponse,
} from "./dto/response/branches-response-dt";

export class BranchService extends BaseService {
  private resource = "/api/branches";

  async getBranches(
    params: BranchesReqDTO,
    onSuccess: (data: GetBranchesResponse) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(this.resource + "/", params);
    await this.doGet<GetBranchesResponse>(reqParams, onSuccess, onError);
  }

  async addBranch(
    params: AddBranchDTO,
    onSuccess: (data: Branch) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(this.resource, params);

    await this.doPost<Branch>(reqParams, onSuccess, onError);
  }

  async deleteBranch(
    branchId: number,
    onSuccess: (data: Branch[]) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(`${this.resource}/${branchId}`, {});
    await this.doDelete<Branch[]>(reqParams, onSuccess, onError);
  }

  async updateBranch(
    updatedBranch: UpdatedBranch,
    onSuccess: (data: Branch[]) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(this.resource + "", updatedBranch);
    await this.doPatch<Branch[]>(reqParams, onSuccess, onError);
  }

  async getBranchesInformation(
    params: BranchesInformationReq,
    onSuccess: (data: BranchesInformationRes[]) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto("/api/getFristLoginData/", params);
    await this.doGet<BranchesInformationRes[]>(reqParams, onSuccess, onError);
  }
}
