import { TextField } from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Branch } from "../../types";
import { BranchService } from "../../services/branch-service";
import { useToast } from "../../context/toast-context";
import { ButtonForm } from "./button-form";

interface EditBranchFormProps {
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  branch: Branch;
  updateBranch: (branch: Branch) => void;
}

export function EditBranchForm({
  setIsModalOpen,
  branch,
  updateBranch,
}: EditBranchFormProps) {
  const { t } = useTranslation();
  const [branchName, setBranchName] = useState<string>(branch.name);
  const [branchAddress, setBranchAddress] = useState<string>(branch.address);
  const [branchPhone, setBranchPhone] = useState<string>(branch.phone);
  const [errors, setErrors] = useState({
    branchName: "",
    branchAddress: "",
    branchPhone: "",
  });
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const branchService = useMemo(() => new BranchService(), []);

  const validate = () => {
    let data = { branchName: "", branchAddress: "", branchPhone: "" };
    let isValid = true;

    if (!branchName || branchName.length < 3) {
      data.branchName = t("branches.edit.errors.name");
      isValid = false;
    }

    if (!branchAddress || branchAddress.length < 5) {
      data.branchAddress = t("branches.edit.errors.address");
      isValid = false;
    }

    const phoneRegex = /^[\d\s]+$/;
    if (!branchPhone || !phoneRegex.test(branchPhone)) {
      data.branchPhone = t("branches.edit.errors.phone");
      isValid = false;
    }

    setErrors(data);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();
    if (!validate()) {
      setLoading(false);
      return;
    }

    await branchService.updateBranch(
      {
        BranchId: branch.branchId,
        Address: branchAddress,
        Name: branchName,
        Phone: branchPhone,
      },
      (data) => {
        updateBranch(data[0]);
        showToast(t("branches.edit.success"), "success");
      },
      (error) => {
        showToast(error, "error");
      }
    );

    setLoading(false);
    setIsModalOpen && setIsModalOpen(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid px-6 gap-4">
        <TextField
          type="text"
          label={t("branches.edit.name")}
          value={branchName}
          onChange={(e) => setBranchName(e.target.value)}
          error={!!errors.branchName}
          helperText={errors.branchName}
          variant="standard"
          sx={{
            "& .MuiInput-root": {
              color: "#000",
              fontFamily: "Montserrat",
              "&:before": {
                borderBottom: "1px solid #838383",
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: "#898989",
              },
            },
            "& label.Mui-focused": {
              color: "#7e7e7e",
            },
            "& .MuiInputLabel-standard": {
              color: "#000",
              fontWeight: "400",
              fontFamily: "Montserrat",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "#838383",
            },
          }}
        />
        <TextField
          type="text"
          label={t("branches.edit.address")}
          value={branchAddress}
          onChange={(e) => setBranchAddress(e.target.value)}
          error={!!errors.branchAddress}
          helperText={errors.branchAddress}
          variant="standard"
          sx={{
            "& .MuiInput-root": {
              color: "#000",
              fontFamily: "Montserrat",
              "&:before": {
                borderBottom: "1px solid #838383",
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: "#898989",
              },
            },
            "& label.Mui-focused": {
              color: "#7e7e7e",
            },
            "& .MuiInputLabel-standard": {
              color: "#000",
              fontWeight: "400",
              fontFamily: "Montserrat",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "#838383",
            },
          }}
        />

        <TextField
          type="text"
          label={t("branches.edit.phone")}
          value={branchPhone}
          onChange={(e) => setBranchPhone(e.target.value)}
          error={!!errors.branchPhone}
          helperText={errors.branchPhone}
          variant="standard"
          sx={{
            "& .MuiInput-root": {
              color: "#000",
              fontFamily: "Montserrat",
              "&:before": {
                borderBottom: "1px solid #838383",
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: "#898989",
              },
            },
            "& label.Mui-focused": {
              color: "#7e7e7e",
            },
            "& .MuiInputLabel-standard": {
              color: "#000",
              fontWeight: "400",
              fontFamily: "Montserrat",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "#838383",
            },
          }}
        />
      </div>

      <div className="flex justify-center mt-12 mx-6">
        <ButtonForm loading={loading}>{t("branches.edit.submit")}</ButtonForm>
      </div>
    </form>
  );
}
