import { createBrowserRouter } from "react-router-dom";
import Example from "./screens/Example";
import { DashboardLayout } from "./layouts/DashboardLayout";
import { Teams } from "./screens/Teams";
import { Login } from "./screens/Login";
import { Branches } from "./screens/Branches";
import { AccountVerified } from "./screens/AccountVerified";
import { RecoverPassword } from "./screens/RecoverPassword";
import { ChangePassword } from "./screens/ChangePassword";
import { ChangedPassword } from "./screens/ChangedPassword";
import { VerifyManager } from "./screens/VerifyManager";
import { Groups } from "./screens/Groups";
import { GroupsByBranch } from "./screens/GroupsByBranch";
import { Dashboard } from "./screens/Dashboard";
import { ProtectedRoutes } from "./routes/protected-route";
import { VerifyEmployee } from "./screens/VerifyEmployee";
import { VerifiedEmployee } from "./screens/VerifiedEmployee";
import { Messages } from "./screens/Messages";
import { SetupBranch } from "./screens/SetupBranch";
import { SetupTeam } from "./screens/SetupTeam";
import { YourTeam } from "./screens/YourTeam";
import { SetupPercentages } from "./screens/SetupPercentages";
import { WelcomeEmployee } from "./screens/WelcomeEmployee";
import { ForgotPassword } from "./screens/ForgotPassword";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Example />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoutes>
        <DashboardLayout />
      </ProtectedRoutes>
    ),
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "teams",
        element: <Teams />,
      },
      // manejamos la selección de sucursales previamente
      {
        path: "groups",
        element: <Groups />,
      },
      // manejamos el contenido de los grupos dependiendo del branch seleccionado
      {
        path: "groups/:branchId",
        element: <GroupsByBranch />,
      },
      {
        path: "messages",
        element: <Messages />,
      },
      {
        path: "settings",
        element: <p>Configuracion</p>,
      },
      {
        path: "branches",
        element: <Branches />,
      },
    ],
  },
  {
    path: "/account-verified",
    element: (
      <ProtectedRoutes>
        <AccountVerified />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/recover-password",
    element: <RecoverPassword />,
  },
  {
    path: "/change-password",
    element: <ChangePassword />,
  },
  {
    path: "/changed-password",
    element: <ChangedPassword />,
  },
  {
    path: "/verify-manager",
    element: <VerifyManager />,
  },
  {
    path: "welcome-employee",
    element: <WelcomeEmployee />,
  },
  {
    path: "/verify-employee",
    element: <VerifyEmployee />,
  },
  {
    path: "/verified-employee",
    element: <VerifiedEmployee />,
  },
  {
    path: "/setup-branch",
    element: (
      <ProtectedRoutes>
        <SetupBranch />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/setup-team",
    element: (
      <ProtectedRoutes>
        <SetupTeam />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/your-team",
    element: (
      <ProtectedRoutes>
        <YourTeam />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/setup-percentages",
    element: (
      <ProtectedRoutes>
        <SetupPercentages />
      </ProtectedRoutes>
    ),
  },
]);

export default router;
