import { Employee, User } from "../types";
import BaseService from "./base-service";
import {
  AddEmployeeFirstLoginReq,
  ChangePasswordReq,
  EditEmployeeFirstLogin,
  EditEmployeeReq,
  GetUnverifiedUserReq,
  UpdateFirstLoginReq,
  VerifyEmployeeReq,
  VerifyManagerReq,
} from "./dto/request/user-request-dto";
import { RequestDto } from "./dto/request/request-dto";
import {
  AddedEmployee,
  GetEmployeeResponse,
  Manager,
  UnverifiedUser,
  VerifiedUser,
} from "./dto/response/user-response-dto";

interface EmployeesReqDTO {
  limit: number;
  offset: number;
  branchid?: number;
  groupid?: number;
}

export class UserService extends BaseService {
  private resource = "/api/users";

  async getUser(
    params: null,
    onSuccess: (data: User) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(this.resource + "/me", params);
    await this.doGet<User>(reqParams, onSuccess, onError);
  }

  async getEmployees(
    params: EmployeesReqDTO,
    onSuccess: (data: GetEmployeeResponse) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(this.resource + "/employees", params);
    await this.doGet<GetEmployeeResponse>(reqParams, onSuccess, onError);
  }

  async addEmployees(
    params: AddEmployeeFirstLoginReq,
    onSuccess: (data: AddedEmployee[]) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(this.resource + "/employees", params);
    await this.doPost<AddedEmployee[]>(reqParams, onSuccess, onError);
  }

  async editEmployees(
    params: EditEmployeeReq,
    onSuccess: (data: Employee) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(this.resource + "/employees", params);
    await this.doPatch<Employee>(reqParams, onSuccess, onError);
  }

  async deleteEmployees(
    userId: number,
    onSuccess: (data: any) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(
      `${this.resource}/employees/${userId}`,
      {}
    );
    await this.doDelete<any>(reqParams, onSuccess, onError);
  }

  async getUnverifiedUser(
    params: GetUnverifiedUserReq,
    onSuccess: (data: UnverifiedUser) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto("/users/employees/verify", params);
    await this.doGet<UnverifiedUser>(reqParams, onSuccess, onError);
  }

  async verifyEmployee(
    params: VerifyEmployeeReq,
    onSuccess: (data: VerifiedUser) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto("/users/employees/verify", params);
    await this.doPatch<VerifiedUser>(reqParams, onSuccess, onError);
  }

  async verifyManager(
    params: VerifyManagerReq,
    onSuccess: (data: VerifiedUser) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto("/users/managers/verify", params);
    await this.doPatch<VerifiedUser>(reqParams, onSuccess, onError);
  }

  async sendRecoverEmail(
    params: { email: string },
    onSuccess: () => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto("/users/change_password", params);
    await this.doGet(reqParams, onSuccess, onError);
  }

  async verifyChangePassword(
    params: GetUnverifiedUserReq,
    onSuccess: (data: Manager) => void,
    onError: (erroMessage: string) => void
  ) {
    const reqParams = new RequestDto(
      "/users/employees/verifychangepassword",
      params
    );
    await this.doGet<Manager>(reqParams, onSuccess, onError);
  }

  async changePassword(
    params: ChangePasswordReq,
    onSuccess: (data: Manager) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto("/users/managers/change_password", params);
    await this.doPatch<Manager>(reqParams, onSuccess, onError);
  }

  async editEmployeeFirstLogin(
    params: EditEmployeeFirstLogin,
    onSuccess: (data: any) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(
      this.resource + "/employee/first-login",
      params
    );
    await this.doPatch<any>(reqParams, onSuccess, onError);
  }

  async updateFirstLogin(
    params: UpdateFirstLoginReq,
    onSuccess: (data: any) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(this.resource + "/firstlogin", params);
    await this.doPatch<any>(reqParams, onSuccess, onError);
  }
}
