import { Modal, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Group } from "../../types";
import { AdjustPercentagesForm } from "../groups/adjust-percentages-form";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  maxWidth: 1200,
  maxHeight: "80vh",
  overflowY: "auto",
  bgcolor: "#fff",
  border: "1px solid #898989",
  boxShadow: 24,
  borderRadius: 4,
};

interface AdjustPercentagesModalProps {
  groups: Group[];
  onUpdatePercentages: (
    updatedGroups: {
      GroupId: number;
      Percent: number;
    }[]
  ) => void;
  onClose: () => void;
}

export function AdjustPercentagesModal({
  groups,
  onUpdatePercentages,
  onClose,
}: AdjustPercentagesModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby={t("teams.group.delete.adjust.title")}
      aria-describedby={t("teams.group.delete.adjust.title")}
    >
      <Box sx={style}>
        <div className="bg-gray-light text-center text-xl font-normal text-black py-8 px-2">
          <h1>{t("teams.group.delete.adjust.title")}</h1>
        </div>
        <div className="p-8">
          <AdjustPercentagesForm
            groups={groups}
            onSubmit={onUpdatePercentages}
          />
        </div>
      </Box>
    </Modal>
  );
}
