import { FormControlLabel, Switch, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Branch } from "../../types";
import { BranchService } from "../../services/branch-service";
import React, { useMemo, useState } from "react";
import { useToast } from "../../context/toast-context";
import { ButtonForm } from "./button-form";

interface AddBranchWithTipForm {
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  addBranch?: (newBranch: Branch) => void;
}

export function AddBranchWithSplitForm({
  setIsModalOpen,
  addBranch,
}: AddBranchWithTipForm) {
  const { t } = useTranslation();
  const branchService = useMemo(() => new BranchService(), []);
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [sameAmount, setSameAmount] = useState(false);

  const [errors, setErrors] = useState({
    name: "",
    address: "",
    phone: "",
  });

  const validate = (name: string, address: string, phone: string) => {
    let errors = {
      name: "",
      address: "",
      phone: "",
    };
    let isValid = true;

    if (!name) {
      errors.name = t("branches.create.errors.name");
      isValid = false;
    }

    if (!address) {
      errors.address = t("branches.create.errors.address");
      isValid = false;
    }

    if (!phone) {
      errors.phone = t("branches.create.errors.phone");
      isValid = false;
    }
    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const name = formData.get("name") as string;
    const address = formData.get("address") as string;
    const phone = formData.get("phone") as string;

    if (!validate(name, address, phone)) {
      setLoading(false);
      return;
    }
    await branchService.addBranch(
      {
        Address: address,
        Name: name,
        Phone: phone,
        EqualSplitTip: sameAmount,
      },
      (data) => {
        // Para actualizar la UI en pantalla branches
        addBranch &&
          addBranch({
            employeeCount: 0,
            name: data.name,
            active: data.active,
            address: data.address,
            phone: data.phone,
            branchId: data.branchId,
            companyId: data.companyId,
            equalSplitTip: data.equalSplitTip,
          });
        showToast(t("branches.create.success"), "success");
      },
      (error) => {
        showToast(error, "error");
      }
    );
    setLoading(false);
    setIsModalOpen && setIsModalOpen(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="px-6 pb-4">
          <FormControlLabel
            sx={{
              "& .MuiTypography-root": {
                fontFamily: "Montserrat",
                color: "#000",
              },
              "& .MuiFormControlLabel-label.Mui-disabled": {
                color: "#dfdfdf",
              },
            }}
            control={
              <Switch
                checked={sameAmount}
                onChange={(e) => setSameAmount(e.target.checked)}
                sx={{
                  "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                    bgcolor: "#EF0BB8",
                  },
                  "& .MuiSwitch-track": {
                    bgcolor: "#898989",
                  },
                  "& .MuiSwitch-thumb": {
                    bgcolor: "#EF0BB8",
                  },
                }}
              />
            }
            label="Todos los empleados cobran la misma propina"
          />
        </div>
        <div className="grid px-6 gap-4">
          <TextField
            type="text"
            name="name"
            error={!!errors.name}
            helperText={errors.name}
            label={t("branches.create.name")}
            variant="standard"
            sx={{
              "& .MuiInput-root": {
                color: "#000",
                fontFamily: "Montserrat",
                "&:before": {
                  borderBottom: "1px solid #838383",
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#898989",
                },
              },
              "& label.Mui-focused": {
                color: "#7e7e7e",
              },
              "& .MuiInputLabel-standard": {
                color: "#000",
                fontWeight: "400",
                fontFamily: "Montserrat",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#838383",
              },
            }}
          />
          <TextField
            type="text"
            name="address"
            error={!!errors.address}
            helperText={errors.address}
            label={t("branches.create.address")}
            variant="standard"
            sx={{
              "& .MuiInput-root": {
                color: "#000",
                fontFamily: "Montserrat",
                "&:before": {
                  borderBottom: "1px solid #838383",
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#898989",
                },
              },
              "& label.Mui-focused": {
                color: "#7e7e7e",
              },
              "& .MuiInputLabel-standard": {
                color: "#000",
                fontWeight: "400",
                fontFamily: "Montserrat",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#838383",
              },
            }}
          />

          <TextField
            type="text"
            name="phone"
            error={!!errors.phone}
            helperText={errors.phone}
            label={t("branches.create.phone")}
            onInput={(e) => {
              const input = e.target as HTMLInputElement;
              let value = input.value;

              value = value.replace(/[^\d\s+]/g, ""); // Permitimos numeros, espacios y +
              value = value.replace(/\s+/g, " "); // No permitimos mas de 1 espacio consecutivo

              // Si ingresa + que sea lo primero de la cadena
              value = value.replace(/(?!^)\+/g, "");
              if (value.includes("+") && value[0] !== "+") {
                value = "+" + value.replace(/\+/g, "");
              }

              input.value = value;
            }}
            variant="standard"
            sx={{
              "& .MuiInput-root": {
                color: "#000",
                fontFamily: "Montserrat",
                "&:before": {
                  borderBottom: "1px solid #838383",
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#898989",
                },
              },
              "& label.Mui-focused": {
                color: "#7e7e7e",
              },
              "& .MuiInputLabel-standard": {
                color: "#000",
                fontWeight: "400",
                fontFamily: "Montserrat",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#838383",
              },
            }}
          />
        </div>

        <div className="flex justify-center mt-12 mx-6">
          <ButtonForm loading={loading}>
            {t("branches.create.submit")}
          </ButtonForm>
        </div>
      </form>
    </>
  );
}
