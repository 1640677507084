import { useState } from "react";
import { Box, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TermsAndConditions } from "../TermsAndConditions";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 1200,
  maxHeight: "80vh",
  overflowY: "auto",
  bgcolor: "#fff",
  border: "1px solid #898989",
  boxShadow: 24,
  borderRadius: 4,
  p: "2rem 8rem",
};

export function LegalModal() {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <button
        onClick={() => setOpenModal(true)}
        className="bg-gray-dark text-white inline-flex items-center justify-center whitespace-nowrap rounded-2xl text-sm font-medium transition-colors hover:bg-gray-dark/90 px-5 py-1 absolute right-4 top-4 md:right-8 md:top-8"
      >
        {t("login.legal")}
      </button>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby={t("login.legal")}
        aria-describedby="Términos y condiciones del usuario"
      >
        <Box sx={style}>
          <TermsAndConditions />
        </Box>
      </Modal>
    </>
  );
}
