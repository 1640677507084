import { useEffect, useMemo, useState } from "react";
import { useToast } from "../../context/toast-context";
import { GroupService } from "../../services/group-service";
import { useTranslation } from "react-i18next";
import { InputAdornment, TextField } from "@mui/material";
import { ButtonForm } from "./button-form";
import { Group } from "../../types";

interface SetupPercentagesFormProps {
  groups: Group[];
  updatePercentages: (
    updatedGroups: {
      GroupId: number;
      Percent: number;
    }[]
  ) => void;
}

export function SetupPercentagesForm({
  groups,
  updatePercentages,
}: SetupPercentagesFormProps) {
  const [percentages, setPercentages] = useState<string[]>(
    groups.map((group) => group.percent.toString().replace(".", ","))
  );
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const groupService = useMemo(() => new GroupService(), []);
  const { t } = useTranslation();

  const handleInputChange = (index: number, value: string) => {
    const formattedValue = value.replace(",", ".");

    const newPercentages = [...percentages];
    const inputValue = Number(formattedValue);

    if (isNaN(inputValue) || inputValue < 0) {
      return;
    }

    const decimalIndex = formattedValue.indexOf(".");
    if (decimalIndex !== -1 && formattedValue.length - decimalIndex - 1 > 2) {
      return; // No permitimos mas de 2 decimales
    }

    // Se verifica si al sumar excede el 100%
    const totalExcludingCurrent = newPercentages.reduce((acc, curr, idx) => {
      if (idx === index) {
        return acc;
      }
      const parsedValue = Number(curr.replace(",", "."));
      return acc + (isNaN(parsedValue) ? 0 : parsedValue);
    }, 0);

    if (totalExcludingCurrent + inputValue > 100) {
      setError(t("groups.percentages.errors.greater"));
      return;
    } else {
      setError(null);
    }

    newPercentages[index] = formattedValue.replace(".", ",");
    setPercentages(newPercentages);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    const total = Number(
      percentages
        .reduce((acc, curr) => acc + Number(curr.replace(",", ".") || 0), 0)
        .toFixed(2)
    );

    if (total !== 100) {
      setError(t("groups.percentages.errors.equal"));
      setLoading(false);
      return;
    }

    const result = groups.map((group, index) => ({
      GroupId: group.groupId,
      Percent: parseFloat(
        Number(percentages[index].replace(",", ".")).toFixed(2)
      ),
    }));

    await groupService.setPercentages(
      {
        groups: result,
      },
      (data) => {
        updatePercentages(result);
        showToast(t("groups.percentages.success"), "success");
      },
      (error) => showToast(error, "error")
    );
    setLoading(false);
  };

  useEffect(() => {
    setPercentages(
      groups.map((group) => group.percent.toString().replace(".", ","))
    );
  }, [groups]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-[auto_1fr_auto] px-6 gap-2">
        <div className="grid gap-4 max-w-56">
          {percentages.map((percentage, index) => (
            <TextField
              key={index}
              type="text"
              label={groups[index].name}
              variant="standard"
              value={percentage}
              onChange={(e) => handleInputChange(index, e.target.value)}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <span className="text-[#dfdfdf]">%</span>
                    </InputAdornment>
                  ),
                },
              }}
              sx={{
                "& .MuiInput-root": {
                  color: "#dfdfdf",
                  fontSize: "1.5rem",
                  fontFamily: "Montserrat",
                  "&:before": {
                    borderBottom: "1px solid #dfdfdf",
                  },
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "#898989",
                  },
                },
                "& label.Mui-focused": {
                  color: "#dfdfdf",
                },
                "& .MuiInputLabel-standard": {
                  color: "#dfdfdf",
                  fontWeight: "400",
                  fontFamily: "Montserrat",
                  fontSize: "1.5rem",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#dfdfdf",
                },
              }}
            />
          ))}
        </div>

        <div role="none" className="h-full w-[1px] shrink-0 bg-gray-light" />

        <div className="flex w-[6ch] h-full items-center justify-center text-6xl text-primary">
          {Number(
            percentages
              .reduce(
                (acc, curr) => acc + Number(curr.replace(",", ".") || 0),
                0
              )
              .toFixed(2)
          )}
          %
        </div>
      </div>

      {error && <div className="mt-4 text-red text-center">{error}</div>}

      <div className="flex justify-center mt-24 mx-6">
        <div className="w-full max-w-sm">
          <ButtonForm
            loading={loading}
            disabled={
              Number(
                percentages
                  .reduce(
                    (acc, curr) => acc + Number(curr.replace(",", ".") || 0),
                    0
                  )
                  .toFixed(2)
              ) > 100
            }
          >
            {t("groups.percentages.submit")}
          </ButtonForm>
        </div>
      </div>
    </form>
  );
}
