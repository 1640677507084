import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Group } from "../../types";
import { AdjustPercentagesModal } from "../modals/AdjustPercentageModal";
import { useToast } from "../../context/toast-context";
import { GroupService } from "../../services/group-service";
import { useParams } from "react-router-dom";

interface DeleteGroupProps {
  group: Group;
  groups: Group[];
  onUpdatePercentages: (
    updatedGroups: { GroupId: number; Percent: number }[]
  ) => void;
  onDeleteGroup: (groupId: number) => void;
}

export function DeleteGroup({
  group,
  groups,
  onDeleteGroup,
  onUpdatePercentages,
}: DeleteGroupProps) {
  const { t } = useTranslation();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openAdjustPercentages, setOpenAdjustPercentages] = useState(false);
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const groupService = useMemo(() => new GroupService(), []);
  const params = useParams();

  const handleClickOpen = () => {
    if (group.employeeCount > 0) {
      showToast(t("teams.group.delete.error"), "error");
      return;
    }

    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleDelete = async () => {
    if (parseFloat(group.percent) > 0 && groups.length > 1) {
      setOpenAdjustPercentages(true);
      return;
    }

    await groupService.deleteGroup(
      group.groupId,
      (data) => {
        onDeleteGroup(data[0].groupId);
        showToast(t("teams.group.delete.success"), "success");
      },
      (error) => {
        showToast(error, "error");
      }
    );
    handleCloseConfirmDialog();
  };

  const handleAdjustPercentages = async (
    updatedGroups: { GroupId: number; Percent: number }[]
  ) => {
    setLoading(true);

    await groupService.deleteGroupRedistribution(
      {
        GroupId: group.groupId,
        BranchId: Number(params.branchId!),
        Groups: updatedGroups,
      },
      (data) => {
        onUpdatePercentages(updatedGroups);
        onDeleteGroup(data[0].groupId);
        showToast(t("groups.delete.adjust.success"), "success");
      },
      (error) => showToast(error, "error")
    );

    setLoading(false);
    setOpenAdjustPercentages(false);
    setOpenConfirmDialog(false);
  };

  return (
    <>
      <>
        <Button
          title={t("teams.group.delete.label")}
          onClick={handleClickOpen}
          sx={{
            margin: 0,
            minWidth: "fit-content",
            background: "transparent",
            border: "1px solid #EF0BB8",
            borderRadius: "50%",
            color: "#EF0BB8",
            textTransform: "none",
            fontFamily: "Montserrat",
            fontWeight: 300,
            width: "2.5rem",
            height: "2.5rem",
          }}
        >
          <svg
            fill="#EF0BB8"
            width="16"
            height="16"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M19.587 16.001l6.096 6.096a1.015 1.015 0 010 1.435l-2.151 2.151a1.015 1.015 0 01-1.435 0L16 19.587l-6.097 6.096a1.014 1.014 0 01-1.434 0l-2.152-2.151a1.015 1.015 0 010-1.435l6.097-6.096-6.097-6.097a1.015 1.015 0 010-1.435L8.47 6.318a1.014 1.014 0 011.434 0L16 12.415l6.097-6.097a1.015 1.015 0 011.435 0l2.151 2.152a1.015 1.015 0 010 1.435l-6.096 6.096z" />
          </svg>
        </Button>

        {/* Dialogo de confirmación */}
        <Dialog
          open={openConfirmDialog}
          onClose={handleCloseConfirmDialog}
          aria-labelledby={t("teams.group.delete.label")}
          aria-describedby={t("teams.group.delete.label")}
        >
          <DialogTitle
            sx={{
              padding: "20px 24px",
              fontWeight: 400,
              fontSize: "16px",
              borderRadius: "30px",
              "&.MuiDialogTitle-root": {
                fontFamily: "Montserrat",
              },
            }}
            id="delete-group"
          >
            {t("teams.group.delete.title")}
          </DialogTitle>
          <DialogActions>
            <Button
              sx={{
                fontFamily: "Montserrat",
                color: "#000",
                fontSize: "0.875rem",
                textTransform: "capitalize",
                fontWeight: 300,
              }}
              onClick={handleCloseConfirmDialog}
              disabled={loading}
            >
              {t("teams.group.delete.cancel")}
            </Button>
            <Button
              sx={{
                fontFamily: "Montserrat",
                backgroundColor: "#E60032",
                color: "#fff",
                fontSize: "0.875rem",
                textTransform: "capitalize",
                fontWeight: 300,
                width: "90px",
                "&.Mui-disabled": {
                  opacity: "40%",
                  color: "#fff",
                },
              }}
              onClick={handleDelete}
              disabled={loading}
            >
              {!loading ? (
                t("teams.group.delete.confirm")
              ) : (
                <CircularProgress
                  sx={{
                    color: "#fff",
                  }}
                  size="1.5rem"
                  className="animate-spin "
                />
              )}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Modal para ajustar porcentajes */}
        {openAdjustPercentages && (
          <AdjustPercentagesModal
            groups={groups.filter((g) => g.groupId !== group.groupId)}
            onUpdatePercentages={handleAdjustPercentages}
            onClose={() => setOpenAdjustPercentages(false)}
          />
        )}
      </>
    </>
  );
}
