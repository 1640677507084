import { Box, Modal, Rating, styled } from "@mui/material";
import { CustomCheckbox } from "./custom-checkbox";
import Star from "../icons/Star";
import { Rating as RatingType } from "../../types";
import { forwardRef, useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  maxWidth: 1200,
  maxHeight: "80vh",
  overflowY: "auto",
  bgcolor: "#fff",
  border: "none",
  outline: "none",
  boxShadow: 24,
  borderRadius: 4,
};

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconFilled": {
    color: "#f3c433",
  },
  "& .MuiRating-iconHover": {
    color: "#f3c433",
  },
}));

interface MessageItemProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rating: RatingType;
  onOpen: () => void;
}

export const MessageItem = forwardRef<HTMLDivElement, MessageItemProps>(
  ({ checked, onChange, rating, onOpen }, ref) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);

      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const year = String(date.getUTCFullYear()).slice(-2);

      const hours = String(date.getUTCHours()).padStart(2, "0");
      const minutes = String(date.getUTCMinutes()).padStart(2, "0");

      return `${day}/${month}/${year} ${hours}:${minutes}`;
    };

    const handleOpenMessage = () => {
      setIsModalOpen(true);
      onOpen();
    };

    return (
      <>
        <div
          ref={ref}
          role="button"
          onClick={handleOpenMessage}
          className={`rounded-full ${
            rating.isRead ? "bg-white" : "bg-[#e9ebf2]"
          } w-full font-semibold text-[#30302f] flex items-center gap-4 px-6 cursor-pointer hover:shadow-md hover:bg-opacity-80 transition-all`}
        >
          <div className="flex items-center gap-2 w-72 pr-10">
            <CustomCheckbox
              id={rating.ratingId.toString()}
              checked={checked}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                e.stopPropagation();
                onChange(e);
              }}
            />
            <div title={rating.usersName} className="truncate w-48">
              {rating.usersName}
            </div>
          </div>

          <div className="flex-1 truncate" title={rating.message}>
            {rating.message}
          </div>

          <div className="flex items-center justify-center min-w-[80px]">
            <StyledRating
              name="rating"
              value={rating.rating}
              size="small"
              icon={<Star />}
              emptyIcon={<Star />}
              sx={{
                pointerEvents: "none",
              }}
            />
          </div>

          <div className="truncate min-w-[100px] text-center">
            {rating.branchName}
          </div>

          <div className="truncate min-w-[150px] text-right">
            {formatDate(rating.createdDate)}
          </div>
        </div>

        {isModalOpen && (
          <Modal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            aria-labelledby={""}
            aria-describedby={""}
          >
            <Box sx={style}>
              <div className="p-5 text-xl font-semibold">
                <h1>Detalles del mensaje</h1>
              </div>
              <div className="py-2 px-5 flex justify-between items-start">
                <div className="flex flex-col">
                  <h2 className="font-semibold tracking-tight">
                    {rating.usersName}
                  </h2>
                  <span className="text-sm ">{rating.branchName}</span>
                </div>
                <div>
                  <StyledRating
                    name="rating"
                    value={rating.rating}
                    size="small"
                    icon={<Star />}
                    emptyIcon={<Star />}
                    sx={{
                      pointerEvents: "none",
                    }}
                  />
                </div>
              </div>

              <span className="text-md text-gray-dark px-5">
                {formatDate(rating.createdDate)}
              </span>

              <div className="m-5 p-3 bg-[#f4f4f5] rounded-lg">
                <p className="text-pretty">{rating.message}</p>
              </div>
            </Box>
          </Modal>
        )}
      </>
    );
  }
);
