import { Box, Button, IconButton, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DivixLogo from "../assets/img/logos/divixBlanco.png";
import { useEffect, useMemo, useState } from "react";
import { LeftArrow } from "../components/icons/LeftArrow";
import { Group } from "../types";
import { GroupService } from "../services/group-service";
import { useToast } from "../context/toast-context";
import { Loading } from "../components/Loading";
import { AddGroupForm } from "../components/form/add-group-form";
import { SetupPercentagesForm } from "../components/form/setup-percentages-form";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  maxWidth: 1200,
  maxHeight: "80vh",
  overflowY: "auto",
  bgcolor: "#fff",
  border: "1px solid #898989",
  boxShadow: 24,
  borderRadius: 4,
};

interface BranchState {
  branchId: number;
  branchName: string;
}

export function SetupPercentages() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [groups, setGroups] = useState<Group[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const groupService = useMemo(() => new GroupService(), []);
  const { showToast } = useToast();

  const state = location.state as BranchState | undefined;

  const goBack = () => {
    navigate(-1);
  };

  const onUpdatePercentages = (
    updatedGroups: { GroupId: number; Percent: number }[]
  ) => {
    navigate("/dashboard");
  };

  const onAddGroup = (newGroup: Group) => {
    setGroups((prev) => [...prev, newGroup]);
  };

  useEffect(() => {
    if (!state || !state.branchId || !state.branchName) {
      navigate("/dashboard");
    }
  }, [state, navigate]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchGroups = async () => {
      setIsLoading(true);

      if (state) {
        try {
          await groupService.getGroups(
            state.branchId,
            (data) => {
              if (!signal.aborted) {
                setGroups(data);
              }
            },
            (errorMessage) => {
              if (!signal.aborted) {
                showToast(errorMessage, "error");
              }
            }
          );
        } finally {
          if (!signal.aborted) {
            setIsLoading(false);
          }
        }
      }
    };

    fetchGroups();

    return () => {
      controller.abort();
    };
  }, [groupService, showToast, state]);

  return (
    <div className="w-full h-screen relative bg-dark-gradient-to-b">
      <Link to="/dashboard" className="absolute top-6 left-6">
        <img width={62} height={62} src={DivixLogo} alt="Divix Logo" />
      </Link>

      <div className="absolute top-6 right-6">
        <Button
          type="button"
          onClick={() => navigate("/dashboard")}
          sx={{
            padding: "2px 8px",
            width: "12rem",
            background: "#EF0BB8",
            borderRadius: "16px",
            color: "white",
            textTransform: "none",
            fontFamily: "Montserrat",
            fontWeight: 300,
            "&.Mui-disabled": {
              opacity: "40%",
              color: "#fff",
            },
          }}
        >
          {t("skip")}
        </Button>
        <Button
          type="button"
          onClick={() => setIsModalOpen(true)}
          sx={{
            padding: "2px 8px",
            marginLeft: "10px",
            width: "16rem",
            background: "#575756",
            borderRadius: "16px",
            color: "white",
            textTransform: "none",
            fontFamily: "Montserrat",
            fontWeight: 300,
            "&.Mui-disabled": {
              opacity: "40%",
              color: "#fff",
            },
          }}
        >
          {t("groups.percentages.setup.group")}
        </Button>
        <IconButton aria-label="Volver" onClick={goBack} color="primary">
          <LeftArrow />
        </IconButton>
      </div>

      {isLoading && <Loading sidebar={false} />}
      {!isLoading && groups.length === 0 && (
        <div className="w-full h-full flex justify-center items-center text-white text-2xl">
          <p>{t("groups.empty")}</p>
        </div>
      )}

      {!isLoading && state && groups.length > 0 && (
        <main className="container mx-auto">
          <div className="flex flex-col text-white mx-auto justify-center items-center pt-32">
            <h1 className="font-light text-center tracking-wide text-3xl">
              {t("groups.percentages.setup.title")}
            </h1>
            <h2 className="pt-6 text-[#dfdfdf] text-xl">
              {t("groups.percentages.title")} {state.branchName}
            </h2>

            <div className="flex flex-col mt-10 justify-center items-center">
              <SetupPercentagesForm
                updatePercentages={onUpdatePercentages}
                groups={groups}
              />
            </div>
          </div>
        </main>
      )}

      {isModalOpen && state && (
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby={t("teams.group.create.title")}
          aria-describedby={t("teams.group.create.title")}
        >
          <Box sx={style}>
            <div className="bg-gray-light text-center text-xl font-normal text-black py-8 px-2">
              <h1>{t("teams.group.create.title")}</h1>
            </div>
            <div className="p-8">
              <AddGroupForm
                branchNumberId={state.branchId}
                setIsModalOpen={setIsModalOpen}
                addGroup={onAddGroup}
              />
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
}
