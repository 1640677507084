import {
  Alert,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
} from "@mui/material";
import { FormEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthService } from "../../services/auth-service";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginResponseDTO } from "../../services/dto/response/auth/login-response-dto";
import { useAuth } from "../../context/auth-context";
import { Visibility } from "../icons/Visibility";
import { VisibilityOff } from "../icons/VisibilityOff";
import { getTokenPayload } from "../../utils/token";
import { UserService } from "../../services/user-service";

export function LoginForm() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const { login } = useAuth();
  const userService = useMemo(() => new UserService(), []);

  const navigate = useNavigate();
  const { state } = useLocation();

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const validate = () => {
    let error = { email: "", password: "" };
    let isValid = true;

    if (!email) {
      error.email = "Email es requerido";
      isValid = false;
    }

    if (!password) {
      error.password = "Contraseña es requerida";
      isValid = false;
    }
    setErrors(error);
    return isValid;
  };

  const handleNotificationClose = () => {
    setOpenNotification(false);
  };

  const postSuccess = async (data: LoginResponseDTO) => {
    if (data.Token) {
      login(data.Token);
      const payload = getTokenPayload(data.Token);
      if (payload.isFirstLogin) {
        await userService.updateFirstLogin(
          {
            IsFirstLogin: false,
            UserId: payload.UserId,
          },
          () => {
            navigate("/setup-branch");
          },
          (error) => {
            setNotificationMessage(error);
            setOpenNotification(true);
          }
        );
      } else {
        navigate(state?.path || "/dashboard");
      }
    }
  };
  const postError = (errorMessage: string) => {
    setNotificationMessage(errorMessage || "Ocurrió un error inesperado.");
    setOpenNotification(true);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();

    if (validate()) {
      const authService = new AuthService();
      await authService.login(
        {
          Email: email.toLowerCase(),
          Password: password,
        },
        postSuccess,
        postError
      );
    }

    setLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="grid px-6 gap-2">
          <TextField
            id="field1"
            autoComplete="off"
            type="text"
            value={email}
            error={!!errors.email}
            slotProps={{
              input: {
                className: "custom-autofill",
              },
            }}
            helperText={errors.email}
            onChange={(e) => setEmail(e.target.value)}
            label={
              <div className="flex gap-4 items-center">
                <svg
                  fill="#838383"
                  width="16"
                  height="16"
                  viewBox="0 0 1920 1920"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1920 428.266v1189.54l-464.16-580.146-88.203 70.585 468.679 585.904H83.684l468.679-585.904-88.202-70.585L0 1617.805V428.265l959.944 832.441L1920 428.266ZM1919.932 226v52.627l-959.943 832.44L.045 278.628V226h1919.887Z"
                    fillRule="evenodd"
                  />
                </svg>
                {t("login.form.email")}
              </div>
            }
            variant="standard"
            sx={{
              "& .MuiInput-root": {
                color: "#fff",
                fontFamily: "Montserrat",
                "&:before": {
                  borderBottom: "1px solid #838383",
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#898989",
                },
              },
              "& label.Mui-focused": {
                color: "#a9a9a9",
              },
              "& .MuiInputLabel-standard": {
                color: "#a9a9a9",
                fontWeight: "300",
                fontFamily: "Montserrat",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#838383",
              },
            }}
          />
          <TextField
            autoComplete="new-password"
            id="field2"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!errors.password}
            type={showPassword ? "text" : "password"}
            helperText={errors.password}
            slotProps={{
              input: {
                className: "custom-autofill",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
            label={
              <div className="flex items-center gap-4">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 6V4a4 4 0 1 1 8 0v2h2v10H2V6h2Zm2-2a2 2 0 1 1 4 0v2H6V4Zm1 9V9h2v4H7Z"
                    fill="#898989"
                  />
                </svg>
                {t("login.form.password")}
              </div>
            }
            variant="standard"
            sx={{
              "& .MuiInput-root": {
                color: "#fff",
                fontFamily: "Montserrat",
                "&:before": {
                  borderBottom: "1px solid #838383",
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#898989",
                },
              },
              "& label.Mui-focused": {
                color: "#a9a9a9",
              },
              "& .MuiInputLabel-standard": {
                color: "#a9a9a9",
                fontWeight: "300",
                fontFamily: "Montserrat",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#838383",
              },
            }}
          />
        </div>

        <div className="w-full text-[#898989] italic text-xs px-12 py-5 flex items-center justify-between">
          <FormControlLabel
            sx={{
              "& .MuiTypography-root": {
                fontSize: "0.75rem",
                color: "#898989",
                lineHeight: "1rem",
                fontStyle: "italic",
                paddingLeft: "2px",
                fontFamily: "Montserrat",
              },
            }}
            control={
              <Checkbox
                sx={{
                  color: "#898989",
                  padding: 0,
                  "&.Mui-checked": {
                    color: "#EF0BB8",
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: 16,
                    margin: 0,
                    padding: 0,
                  },
                }}
                defaultChecked
              />
            }
            label={t("login.form.remember")}
          />
          <a className="hover:underline" href="/forgot-password">
            <span>{t("login.form.forgot")}</span>
          </a>
        </div>

        <div className="w-3/4 mx-auto  mt-6">
          <button
            type="submit"
            disabled={loading}
            className="w-full transition-colors leading-5 bg-[#4f4f4f] hover:bg-opacity-90 text-white font-light rounded-3xl py-0.5 px-4"
          >
            {!loading ? (
              t("login.form.submit")
            ) : (
              <CircularProgress
                sx={{
                  color: "#fff",
                }}
                size="1rem"
                className="animate-spin "
              />
            )}
          </button>
        </div>
      </form>
      {openNotification && (
        <Snackbar
          open={openNotification}
          autoHideDuration={6000}
          onClose={handleNotificationClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert onClose={handleNotificationClose} severity="error">
            {notificationMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
