import { FormControl, FormHelperText, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Branch, Group } from "../../types";
import { useEffect, useMemo, useState } from "react";
import { GroupService } from "../../services/group-service";
import { useToast } from "../../context/toast-context";
import { useParams } from "react-router-dom";
import { BranchService } from "../../services/branch-service";
import { ButtonForm } from "./button-form";

interface AddGroupFormProps {
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  addGroup?: (newGroup: Group) => void;
  showBranches?: boolean;
  branchNumberId?: number;
}

export function AddGroupForm({
  setIsModalOpen,
  addGroup,
  showBranches,
  branchNumberId,
}: AddGroupFormProps) {
  const { t } = useTranslation();
  const [branches, setBranches] = useState<Branch[]>([]);
  const [selectedBranchId, setSelectedBranchId] = useState<
    Branch["branchId"] | null
  >(null);
  const [loading, setLoading] = useState(false);
  const groupService = useMemo(() => new GroupService(), []);
  const branchService = useMemo(() => new BranchService(), []);
  const { showToast } = useToast();
  const params = useParams();

  const [errors, setErrors] = useState({
    name: "",
    branch: "",
  });

  const validate = (name: string, branchId: number | null) => {
    let errors = {
      name: "",
      branch: "",
    };
    let isValid = true;

    if (!name) {
      errors.name = "El nombre es requerido";
      isValid = false;
    }

    if (showBranches && !branchId) {
      errors.branch = "Debe seleccionar una sucursal";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    e.stopPropagation();
    const formData = new FormData(e.currentTarget);
    const name = formData.get("name") as string;
    const branchId =
      branchNumberId ??
      (showBranches ? selectedBranchId : parseInt(params.branchId!));

    if (!validate(name, branchId)) {
      setLoading(false);
      return;
    }
    await groupService.addGroup(
      {
        Name: name,
        BranchId: branchId!,
      },
      (data) => {
        // Se setea el employeeCount en 0 porque la API no lo devuelve
        addGroup &&
          addGroup({
            ...data[0],
            employeeCount: 0,
          });
        showToast(t("teams.group.create.success"), "success");
      },
      (error) => {
        showToast(error, "error");
      }
    );
    setLoading(false);
    setIsModalOpen && setIsModalOpen(false);
  };

  useEffect(() => {
    if (showBranches) {
      const fetchBranches = async () => {
        await branchService.getBranches(
          { limit: 100, offset: 0 },
          (data) => {
            setBranches(data.branches);
          },
          (errorMessage) => {
            showToast(errorMessage, "error");
          }
        );
      };

      fetchBranches();
    }
  }, [branchService, showToast, showBranches]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid px-6 gap-4">
        {showBranches && (
          <FormControl error={!!errors.branch} variant="standard" fullWidth>
            <select
              id="select-branch"
              className="px-2 py-1 h-full rounded-full border border-[#838383] outline-primary"
              value={selectedBranchId ?? ""}
              onChange={(e) => setSelectedBranchId(Number(e.target.value))}
            >
              <option value="">{t("groups.select.label")}</option>
              {branches
                .filter((branch) => branch.equalSplitTip === false)
                .map((branch) => (
                  <option key={branch.branchId} value={branch.branchId}>
                    {branch.name}
                  </option>
                ))}
            </select>
            {errors.branch && <FormHelperText>{errors.branch}</FormHelperText>}
          </FormControl>
        )}
        <TextField
          type="text"
          name="name"
          error={!!errors.name}
          helperText={errors.name}
          label={t("teams.group.create.name")}
          variant="standard"
          sx={{
            "& .MuiInput-root": {
              color: "#000",
              fontFamily: "Montserrat",
              "&:before": {
                borderBottom: "1px solid #838383",
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: "#898989",
              },
            },
            "& label.Mui-focused": {
              color: "#7e7e7e",
            },
            "& .MuiInputLabel-standard": {
              color: "#000",
              fontWeight: "400",
              fontFamily: "Montserrat",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "#838383",
            },
          }}
        />
      </div>

      <div className="flex justify-center mt-12 mx-6">
        <ButtonForm loading={loading}>
          {t("teams.group.create.submit")}
        </ButtonForm>
      </div>
    </form>
  );
}
