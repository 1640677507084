export function LeftArrow() {
  return (
    <svg
      fill="#EF0BB8"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 100 100"
      xmlSpace="preserve"
    >
      <path d="m44.942 50.412 14.037-15.487a1.998 1.998 0 1 0-2.963-2.685L40.784 49.044a1.987 1.987 0 0 0-.493 1.598 1.984 1.984 0 0 0 .647 1.539L57.74 67.412a1.996 1.996 0 0 0 2.826-.138 1.999 1.999 0 0 0-.139-2.824L44.942 50.412z" />
      <path d="M84.133 49.756c0-18.448-15.01-33.457-33.458-33.457S17.218 31.308 17.218 49.756c0 18.449 15.009 33.458 33.457 33.458s33.458-15.009 33.458-33.458zM50.675 79.214c-16.243 0-29.457-13.215-29.457-29.458 0-16.242 13.214-29.457 29.457-29.457 16.243 0 29.458 13.215 29.458 29.457 0 16.243-13.215 29.458-29.458 29.458z" />
    </svg>
  );
}
