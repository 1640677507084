import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { useMemo, useState } from "react";
import { EmployeeUser } from "../../types";
import { BranchData } from "../../services/dto/response/ratings-response-dto";
import { useToast } from "../../context/toast-context";
import { UserService } from "../../services/user-service";

interface MessageFilterProps {
  selectedBranch: number | null;
  selectedEmployee: number | null;
  setSelectedBranch: (branchId: number | null) => void;
  setSelectedEmployee: (employeeId: number | null) => void;
  branches: BranchData[];
}

export function MessageFilter({
  selectedBranch,
  setSelectedBranch,
  selectedEmployee,
  setSelectedEmployee,
  branches,
}: MessageFilterProps) {
  const [employees, setEmployees] = useState<EmployeeUser[]>([]);
  const [branchAnchorEl, setBranchAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const [groupAnchorEl, setGroupAnchorEl] = useState<HTMLElement | null>(null);

  const { showToast } = useToast();
  const userService = useMemo(() => new UserService(), []);

  const handleBranchClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setBranchAnchorEl(e.currentTarget);
  };
  const handleGroupClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setGroupAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setBranchAnchorEl(null);
    setGroupAnchorEl(null);
  };

  const handleBranchSelect = async (branchId: number) => {
    setSelectedBranch(branchId);

    await userService.getEmployees(
      { limit: 100, offset: 0, branchid: branchId },
      (data) => {
        setEmployees(data.users);
      },
      (errorMessage) => {
        showToast(errorMessage, "error");
      }
    );
    handleClose();
  };

  const handleEmployeeSelect = (employeeId: number) => {
    setSelectedEmployee(employeeId);
    handleClose();
  };

  return (
    <div className="flex items-center gap-2">
      <span className="text-[#898989] text-sm font-semibold">Filtrar por</span>
      <div className="flex gap-4">
        <div>
          <Button
            id="filter-branch-button"
            title={
              selectedBranch !== null
                ? branches.find((branch) => branch.branchId === selectedBranch)
                    ?.name || "Sucursal"
                : "Sucursal"
            }
            variant="contained"
            disableElevation
            onClick={handleBranchClick}
            sx={{
              background: "#2D2F32",
              margin: 0,
              padding: "0 10px",
              borderRadius: "999px",
              textTransform: "none",
              fontFamily: "Montserrat",
              fontWeight: 300,
              maxWidth: "150px",
            }}
            endIcon={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#EF0BB8"
                strokeWidth="0"
              >
                <path
                  d="M5.707 9.71a1 1 0 0 0 0 1.415l4.892 4.887a2 2 0 0 0 2.828 0l4.89-4.89a1 1 0 1 0-1.414-1.415l-4.185 4.186a1 1 0 0 1-1.415 0L7.121 9.71a1 1 0 0 0-1.414 0Z"
                  fill="#EF0BB8"
                  stroke="none"
                />
              </svg>
            }
          >
            <div className="truncate">
              {selectedBranch !== null
                ? branches.find((branch) => branch.branchId === selectedBranch)
                    ?.name || "Sucursal"
                : "Sucursal"}
            </div>
          </Button>
          <Popper
            open={Boolean(branchAnchorEl)}
            anchorEl={branchAnchorEl}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper
                  sx={{
                    background: "#2D2F32",
                    marginTop: "2px",
                    maxHeight: "200px",
                    overflowY: "auto",
                    maxWidth: "200px",
                  }}
                  className="custom-scrollbar"
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList>
                      {branches.map((branch) => (
                        <MenuItem
                          sx={{
                            background: "#2D2F32",
                            color: "white",
                            fontFamily: "Montserrat",
                            fontSize: "16px",
                          }}
                          key={branch.branchId}
                          onClick={() => handleBranchSelect(branch.branchId)}
                        >
                          <span className="truncate" title={branch.name}>
                            {branch.name}
                          </span>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        <Button
          id="filter-employee-button"
          variant="contained"
          disabled={!selectedBranch}
          title={
            selectedEmployee !== null
              ? employees.find(
                  (employee) => employee.userId === selectedEmployee
                )?.name || "Empleado"
              : "Empleado"
          }
          disableElevation
          onClick={handleGroupClick}
          sx={{
            background: "#2d2f32",
            margin: 0,
            padding: "0 10px",
            borderRadius: "999px",
            textTransform: "none",
            fontFamily: "Montserrat",
            fontWeight: 300,
            maxWidth: "150px",
            "&.Mui-disabled": {
              backgroundColor: "rgba(45, 47, 50, 0.5)",
              color: "rgba(255, 255, 255, 0.5)",
              "& svg": {
                opacity: 0.5,
              },
            },
          }}
          endIcon={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#EF0BB8"
              strokeWidth="0"
            >
              <path
                d="M5.707 9.71a1 1 0 0 0 0 1.415l4.892 4.887a2 2 0 0 0 2.828 0l4.89-4.89a1 1 0 1 0-1.414-1.415l-4.185 4.186a1 1 0 0 1-1.415 0L7.121 9.71a1 1 0 0 0-1.414 0Z"
                fill="#EF0BB8"
                stroke="none"
              />
            </svg>
          }
        >
          <div className="truncate">
            {selectedEmployee !== null
              ? employees.find(
                  (employee) => employee.userId === selectedEmployee
                )?.name || "Empleado"
              : "Empleado"}
          </div>
        </Button>
        <Popper
          open={Boolean(groupAnchorEl)}
          anchorEl={groupAnchorEl}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper
                sx={{
                  background: "#2D2F32",
                  marginTop: "2px",
                  maxHeight: "200px",
                  overflowY: "auto",
                  maxWidth: "200px",
                }}
                className="custom-scrollbar truncate"
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    {employees.map((employee) => (
                      <MenuItem
                        sx={{
                          background: "#2D2F32",
                          color: "white",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                        }}
                        key={employee.userId}
                        onClick={() => handleEmployeeSelect(employee.userId)}
                      >
                        <span title={employee.name} className="truncate">
                          {employee.name}
                        </span>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
