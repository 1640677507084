import { Link } from "react-router-dom";
import Logo from "../assets/img/logos/X.svg";
import DivixLogo from "../assets/img/logos/divixNegro.png";
import { useTranslation } from "react-i18next";

export function AccountVerified() {
  const { t } = useTranslation();

  return (
    <div className="relative h-screen">
      <Link to="/dashboard" className="absolute top-6 left-6">
        <img width={62} height={62} src={DivixLogo} alt="Divix Logo" />
      </Link>
      <main className="container mx-auto">
        <div className="flex flex-col mx-auto justify-center items-center pt-32">
          <img className="w-14 h-14" src={Logo} alt="Divix Logo" />
          <h1 className="font-normal text-center tracking-wide text-5xl pt-14">
            {t("account-verified.title")}
          </h1>

          <p className="py-10 line-clamp-2 text-center font-medium max-w-xl">
            {t("account-verified.message")}
          </p>
          <Link
            className="px-24 hover:bg-black/90 mx-auto py-1 text-white bg-black rounded-xl"
            to="/dashboard"
          >
            Dashboard
          </Link>
        </div>
      </main>
    </div>
  );
}
