import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export function ChartMetrics() {
  const data = [
    { time: "09HS", propinas: 5000, valoraciones: 10000 },
    { time: "11HS", propinas: 10000, valoraciones: 15000 },
    { time: "13HS", propinas: 15000, valoraciones: 20000 },
    { time: "15HS", propinas: 5000, valoraciones: 10000 },
    { time: "17HS", propinas: 20000, valoraciones: 20000 },
    { time: "19HS", propinas: 30000, valoraciones: 30000 },
    { time: "21HS", propinas: 10000, valoraciones: 25000 },
  ];

  const formatYAxis = (value: any) => {
    if (value > 1000) {
      return `${value / 1000}k`;
    }
    return value;
  };

  return (
    <div className="flex flex-col h-full w-full bg-white rounded-lg p-4 gap-4">
      <div className="flex justify-end pb-4"></div>
      <div className="flex-grow">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}>
            <XAxis dy={12} className="text-sm font-semibold" dataKey="time" />
            <YAxis
              tickFormatter={formatYAxis}
              className="text-sm font-semibold"
              type="number"
              domain={[0, "dataMax"]}
              axisLine={false}
              tickLine={false}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend
              formatter={(value) =>
                value.charAt(0).toUpperCase() + value.slice(1)
              }
              iconType="circle"
              iconSize={14}
              wrapperStyle={{ paddingTop: 10 }}
            />
            <Line
              type="monotone"
              dataKey="propinas"
              stroke="#F3C433"
              strokeWidth="2px"
            />
            <Line
              type="monotone"
              dataKey="valoraciones"
              stroke="#EF0BB8"
              strokeWidth="2px"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
