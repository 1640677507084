import Logo from "../assets/img/logos/X.svg";
import { useTranslation } from "react-i18next";

export function VerifiedEmployee() {
  const { t } = useTranslation();

  return (
    <div className="relative h-screen">
      <main className="container mx-auto">
        <div className="flex flex-col mx-auto justify-center items-center pt-32">
          <img className="w-14 h-14" src={Logo} alt="Divix Logo" />
          <h1 className="font-normal text-center tracking-wide text-5xl pt-14">
            ¡Listo!
          </h1>

          <h2 className="font-normal text-center tracking-wide max-w-md text-2xl pt-6">
            ¡Ya podés recibir propinas de forma automática!
          </h2>

          <p className="py-10 line-clamp-2 text-center font-medium max-w-sm">
            Descargate estos archivos para imprimir tu QR en distintos formatos.
          </p>
          <button className="px-24 hover:bg-black/90 mx-auto py-1 text-white bg-black rounded-xl">
            DESCARGAR
          </button>

          <p className="pt-10 line-clamp-2 text-center font-medium max-w-md">
            Recordá pedirle a tus clientes que valoren tu servicio escaneando el
            QR.
          </p>
          <p className="pt-4 line-clamp-2 text-center text-primary font-medium">
            Si la valoración es positiva, las propinas vienen solas ;)
          </p>
        </div>
      </main>
    </div>
  );
}
