export type Branch = {
  branchId: number;
  companyId: number;
  name: string;
  address: string;
  phone: string;
  active: boolean;
  employeeCount: number;
  equalSplitTip: boolean;
};

export type Group = {
  groupId: number;
  branchId: number;
  name: string;
  percent: string;
  active: boolean;
  createdDate: string;
  updatedDate: string;
  employeeCount: number;
};

export type Employee = {
  userId: number;
  roleId: number;
  companyId: number;
  name: string;
  email: string;
  password: string;
  isVerified: boolean;
  address: string | null;
  phone: string | null;
  birthdate: string | null;
  dni: string | null;
  cuit: string | null;
  cbuCvu: string | null;
  isFirstLogin: boolean;
  active: boolean;
  createdDate: string;
  updatedDate: string | null;
};

type UserGroups = {
  id: number;
  name: string;
  typeId: number;
  typeName: string;
};

export type EmployeeUser = {
  userId: number;
  name: string;
  branchId: number;
  branchName: string;
  isVerified: boolean;
  useQR: boolean;
  email: string;
  userGroups: UserGroups[];
  groupsTypes: UserGroups[];
};

export type User = {
  userId: number;
  name: string;
  email: string;
  rol: string;
  createdDate: string | null;
};

export type TokenPayload = {
  CompanyId: number;
  Email: string;
  Exp: number;
  RoleId: number;
  UserId: number;
  isFirstLogin: boolean;
};

type GroupTypes = {
  groupTypeId: number;
  code: string;
  name: string;
};

export const groupTypes: GroupTypes[] = [
  { groupTypeId: 1, code: "NORMAL", name: "Grupo" },
  { groupTypeId: 2, code: "QR_SPLIT", name: "Con QR" },
];

export type Rating = {
  ratingId: number;
  usersName: string;
  message: string;
  rating: number;
  branchName: string;
  createdDate: string;
  isRead: boolean;
};
