import React, { useMemo, useState } from "react";
import { TextField, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Group } from "../../types";
import { useToast } from "../../context/toast-context";
import { GroupService } from "../../services/group-service";
import { ButtonForm } from "../form/button-form";

interface SetPercentagesFormProps {
  groups: Group[];
  updatePercentages?: (
    updatedGroups: {
      GroupId: number;
      Percent: number;
    }[]
  ) => void;
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function SetPercentagesForm({
  setIsModalOpen,
  updatePercentages,
  groups,
}: SetPercentagesFormProps) {
  const [percentages, setPercentages] = useState<string[]>(
    groups.map((group) => group.percent.toString().replace(".", ","))
  );
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const groupService = useMemo(() => new GroupService(), []);
  const { t } = useTranslation();

  const handleInputChange = (index: number, value: string) => {
    const formattedValue = value.replace(",", ".");

    const newPercentages = [...percentages];
    const inputValue = Number(formattedValue);

    if (isNaN(inputValue) || inputValue < 0) {
      return;
    }

    const decimalIndex = formattedValue.indexOf(".");
    if (decimalIndex !== -1 && formattedValue.length - decimalIndex - 1 > 2) {
      return; // No permitimos mas de 2 decimales
    }

    // Se verifica si al sumar excede el 100%
    const totalExcludingCurrent = newPercentages.reduce((acc, curr, idx) => {
      if (idx === index) {
        return acc;
      }
      const parsedValue = Number(curr.replace(",", "."));
      return acc + (isNaN(parsedValue) ? 0 : parsedValue);
    }, 0);

    if (totalExcludingCurrent + inputValue > 100) {
      setError(t("groups.percentages.errors.greater"));
      return;
    } else {
      setError(null);
    }

    newPercentages[index] = formattedValue.replace(".", ",");
    setPercentages(newPercentages);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    const total = Number(
      percentages
        .reduce((acc, curr) => acc + Number(curr.replace(",", ".") || 0), 0)
        .toFixed(2)
    );

    if (total !== 100) {
      setError(t("groups.percentages.errors.equal"));
      setLoading(false);
      return;
    }

    const result = groups.map((group, index) => ({
      GroupId: group.groupId,
      Percent: parseFloat(
        Number(percentages[index].replace(",", ".")).toFixed(2)
      ),
    }));

    await groupService.setPercentages(
      {
        groups: result,
      },
      (data) => {
        updatePercentages && updatePercentages(result);
        showToast(t("groups.percentages.success"), "success");
      },
      (error) => showToast(error, "error")
    );
    setLoading(false);
    setIsModalOpen && setIsModalOpen(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-[auto_1fr_auto] px-6 gap-2">
        <div className="grid gap-4">
          {percentages.map((percentage, index) => (
            <TextField
              key={index}
              type="text"
              label={groups[index].name}
              variant="standard"
              value={percentage}
              onChange={(e) => handleInputChange(index, e.target.value)}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                },
              }}
              sx={{
                "& .MuiInput-root": {
                  color: "#000",
                  fontFamily: "Montserrat",
                  "&:before": {
                    borderBottom: "1px solid #838383",
                  },
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "#898989",
                  },
                },
                "& label.Mui-focused": {
                  color: "#7e7e7e",
                },
                "& .MuiInputLabel-standard": {
                  color: "#000",
                  fontWeight: "400",
                  fontFamily: "Montserrat",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#838383",
                },
              }}
            />
          ))}
        </div>

        <div role="none" className="h-full w-[1px] shrink-0 bg-gray-light" />

        <div className="flex w-[6ch] h-full items-center justify-center text-6xl text-primary">
          {Number(
            percentages
              .reduce(
                (acc, curr) => acc + Number(curr.replace(",", ".") || 0),
                0
              )
              .toFixed(2)
          )}
          %
        </div>
      </div>

      {error && <div className="mt-4 text-red text-center">{error}</div>}

      <div className="flex justify-center mt-12 mx-6">
        <ButtonForm
          loading={loading}
          disabled={
            Number(
              percentages
                .reduce(
                  (acc, curr) => acc + Number(curr.replace(",", ".") || 0),
                  0
                )
                .toFixed(2)
            ) > 100
          }
        >
          {t("groups.percentages.submit")}
        </ButtonForm>
      </div>
    </form>
  );
}
