import { Avatar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { QRModal } from "../modals/QRModal";
import { DeleteEmployee } from "./delete-employee";
import { EditEmployee } from "./edit-employee";
import { EmployeeUser } from "../../types";
import { ChangeAvatar } from "./change-avatar";

interface EmployeeItemProps {
  employee: EmployeeUser;
  onUpdateEmployees: (updatedEmployees: EmployeeUser[]) => void;
}

export function EmployeeItem({
  employee,
  onUpdateEmployees,
}: EmployeeItemProps) {
  const { t } = useTranslation();
  const emtpyGroup =
    employee.userGroups.length === 0 ||
    (employee.userGroups.length === 1 && employee.userGroups[0].id === null);

  const hasTypeIdGroup = employee.groupsTypes.some(
    (groupType) => groupType.typeId === 1 || groupType.typeId !== null
  );

  return (
    <div className="w-full font-light text-white justify-between items-center flex gap-4 bg-[#2d2f32] py-4 px-6 rounded-xl">
      {/* nombre */}
      <div className="flex h-full basis-1/3 shrink-0 items-center gap-6">
        <div className="text-2xl relative">
          <Avatar
            sx={{
              width: "4rem",
              height: "4rem",
            }}
            src="https://media.istockphoto.com/id/1327592506/vector/default-avatar-photo-placeholder-icon-grey-profile-picture-business-man.jpg?s=612x612&w=0&k=20&c=BpR0FVaEa5F24GIw7K8nMWiiGmbb8qmhfkpXcp1dhQg="
            alt="Avatar Usuario"
          />
          <ChangeAvatar />
        </div>
        <div className="inline-flex w-fit items-center rounded-full border px-3 py-1 text-sm ">
          {employee.name}
        </div>
      </div>

      {/* labels */}

      <div className="flex gap-4 basis-1/3 flex-grow  items-center">
        {hasTypeIdGroup && (
          <div
            className={`inline-flex w-fit items-center rounded-full border px-3 py-1 text-sm max-w-[15ch]  ${
              emtpyGroup && "border-yellow text-yellow"
            }`}
          >
            {!emtpyGroup ? (
              <span
                className="truncate"
                title={`${employee.userGroups
                  .map((group) => group.name)
                  .join(", ")}`}
              >
                {employee.userGroups.map((group) => group.name).join(", ")}
              </span>
            ) : (
              <span className="line-clamp-1">
                {t("branches.view.no-group")}
              </span>
            )}
          </div>
        )}
        <div className="inline-flex w-fit items-center rounded-full border px-3 py-1 text-sm ">
          {employee.branchName}
        </div>
        {!employee.isVerified && (
          <div className="inline-flex w-fit items-center rounded-full border border-orange text-orange px-3 py-1 text-sm ">
            Pendiente
          </div>
        )}
      </div>

      <div className="flex justify-end shrink-0 items-center gap-2">
        {/* employee config */}
        <div className="flex items-center gap-2 xl:gap-8">
          {employee.useQR && employee.isVerified && (
            <QRModal employee={employee} />
          )}
          <EditEmployee
            employee={employee}
            updateEmployees={onUpdateEmployees}
          />
          <DeleteEmployee
            updateEmployees={onUpdateEmployees}
            userId={employee.userId}
          />
        </div>
      </div>
    </div>
  );
}
