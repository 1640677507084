import { Box, Button, Modal } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Branch } from "../../types";
import { AddBranchWithSplitForm } from "../form/add-branch-with-split-form";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "580px",
  maxWidth: 1200,
  maxHeight: "80vh",
  overflowY: "auto",
  bgcolor: "#fff",
  border: "1px solid #898989",
  boxShadow: 24,
  borderRadius: 4,
};

interface AddBranchProps {
  onAddBranch?: (newBranch: Branch) => void;
}

export function AddBranch({ onAddBranch }: AddBranchProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button
        id="add-sucursal-button"
        variant="contained"
        disableElevation
        sx={{
          background: "#2d2f32",
          margin: 0,
          padding: "0 10px",
          borderRadius: "999px",
          textTransform: "none",
          fontFamily: "Montserrat",
          fontWeight: 300,
        }}
        onClick={() => setIsModalOpen(true)}
        endIcon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#EF0BB8"
            strokeWidth="0"
          >
            <path
              d="M5.707 9.71a1 1 0 0 0 0 1.415l4.892 4.887a2 2 0 0 0 2.828 0l4.89-4.89a1 1 0 1 0-1.414-1.415l-4.185 4.186a1 1 0 0 1-1.415 0L7.121 9.71a1 1 0 0 0-1.414 0Z"
              fill="#EF0BB8"
              stroke="none"
            />
          </svg>
        }
      >
        {t("branches.create.title")}
      </Button>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby={t("branches.create.title")}
        aria-describedby={t("branches.create.title")}
      >
        <Box sx={style}>
          <div className="bg-gray-light text-center text-xl font-normal text-black py-8 px-2">
            <h1>{t("branches.create.title")}</h1>
          </div>
          <div className="p-8">
            <AddBranchWithSplitForm
              addBranch={onAddBranch}
              setIsModalOpen={setIsModalOpen}
            />
          </div>
        </Box>
      </Modal>
    </>
  );
}
