import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Logo from "../assets/img/logos/divixNegro.png";

export function TermsAndConditions() {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex justify-between items-center">
        <Typography
          sx={{
            fontFamily: "Montserrat",
            color: "#383838",
          }}
          id="modal-modal-title"
          variant="h4"
          component="h2"
        >
          {t("legal.title")}
        </Typography>
        <img src={Logo} alt="Divix Logo" />
      </div>

      <div className="space-y-4 text-sm text-[#383838] text-pretty hyphens-auto mt-16">
        <p>{t("legal.content")}</p>
        <p>{t("legal.content")}</p>
        <p>{t("legal.content")}</p>
        <p>{t("legal.content")}</p>
        <p>{t("legal.content")}</p>
        <p>{t("legal.content")}</p>
        <p>{t("legal.content")}</p>
        <p>{t("legal.content")}</p>
      </div>
    </>
  );
}
