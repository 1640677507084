import { Box, Button, Modal, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DivixLogo from "../assets/img/logos/divixBlanco.png";
import { useEffect, useMemo, useState } from "react";
import { Group } from "../types";
import { useToast } from "../context/toast-context";
import { SetupTeamForm } from "../components/form/first-login/setup-team-form";
import { Loading } from "../components/Loading";
import { GroupService } from "../services/group-service";
import { GroupFirstLogin } from "../services/dto/request/user-request-dto";
import { BranchService } from "../services/branch-service";
import { EmployeeFirstLoginRes } from "../services/dto/response/user-response-dto";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  maxWidth: 1200,
  maxHeight: "80vh",
  overflowY: "auto",
  bgcolor: "#fff",
  border: "1px solid #898989",
  boxShadow: 24,
  borderRadius: 4,
};

interface BranchState {
  branchId: number;
  branchName: string;
}

export interface BranchInfo {
  active: boolean;
  address: string;
  branchId: number;
  companyId: number;
  employeeCount: string;
  equalSplitTip: boolean;
  name: string;
  phone: string;
}

export function SetupTeam() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [employees, setEmployees] = useState<EmployeeFirstLoginRes[]>([]);
  const [localGroups, setLocalGroups] = useState<GroupFirstLogin[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [branchesInfo, setBranchesInfo] = useState<BranchInfo | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState<string>("");

  const groupService = useMemo(() => new GroupService(), []);
  const branchesService = useMemo(() => new BranchService(), []);
  const { showToast } = useToast();

  const state = location.state as BranchState | undefined;
  const allGroups: GroupFirstLogin[] = [
    ...localGroups,
    ...groups.map((group) => ({
      groupId: group.groupId,
      name: group.name,
    })),
  ];

  const handleCreateGroup = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");

    const formData = new FormData(e.currentTarget);
    const groupName = formData.get("name") as string;

    const isDuplicate = allGroups.some(
      (groups) => groups.name.toLowerCase() === groupName.toLowerCase()
    );

    if (isDuplicate) {
      setError("Ya existe un grupo con ese nombre");
      return;
    }

    if (!groupName) return;
    const newGroup: GroupFirstLogin = {
      groupId: -1,
      name: groupName,
    };

    setLocalGroups([...localGroups, newGroup]);
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!state || !state.branchId || !state.branchName) {
      navigate("/dashboard");
    }
  }, [state, navigate]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const fetchEmployees = async () => {
      setIsLoading(true);

      if (state && state.branchId) {
        try {
          await branchesService.getBranchesInformation(
            {
              limit: 100,
              offset: 0,
              branchid: state.branchId.toString(),
            },
            (data) => {
              setBranchesInfo({
                active: data[0].active,
                address: data[0].address,
                branchId: data[0].branchId,
                companyId: data[0].companyId,
                employeeCount: data[0].employeeCount,
                equalSplitTip: data[0].equalSplitTip,
                name: data[0].name,
                phone: data[0].phone,
              });
              setEmployees(data[0].employees);
              setGroups(data[0].groups);
            },
            (error) => showToast(error, "error")
          );
        } finally {
          if (!signal.aborted) {
            setIsLoading(false);
          }
        }
      }
    };

    fetchEmployees();
    return () => {
      controller.abort();
    };
  }, [showToast, groupService, state, branchesService]);

  return (
    <div className="w-full h-screen relative bg-dark-gradient-to-b">
      <Link to="/dashboard" className="absolute top-6 left-6">
        <img width={62} height={62} src={DivixLogo} alt="Divix Logo" />
      </Link>
      <div className="absolute top-6 right-6">
        <Button
          type="button"
          onClick={() => setIsModalOpen(true)}
          sx={{
            padding: "2px 8px",
            marginLeft: "10px",
            width: "16rem",
            background: "#575756",
            borderRadius: "16px",
            color: "white",
            textTransform: "none",
            fontFamily: "Montserrat",
            fontWeight: 300,
            "&.Mui-disabled": {
              opacity: "40%",
              color: "#fff",
            },
          }}
        >
          {t("groups.percentages.setup.group")}
        </Button>
        <Button
          type="button"
          onClick={() => navigate("/dashboard")}
          sx={{
            padding: "2px 8px",
            width: "12rem",
            background: "#EF0BB8",
            borderRadius: "16px",
            color: "white",
            textTransform: "none",
            fontFamily: "Montserrat",
            marginLeft: "10px",
            fontWeight: 300,
            "&.Mui-disabled": {
              opacity: "40%",
              color: "#fff",
            },
          }}
        >
          {t("skip")}
        </Button>
      </div>
      {state && branchesInfo && (
        <main className="container mx-auto">
          <div className="flex flex-col text-white mx-auto justify-center items-center pt-32">
            <h1 className="font-light text-center tracking-wide text-3xl">
              {t("teams.setup.title")}
            </h1>
            <h2 className="pt-6 text-[#dfdfdf] text-xl">
              {t("teams.setup.subtitle")}{" "}
              <span className="font-semibold">{state.branchName}</span>
            </h2>

            <div className="flex flex-col mt-10 justify-center items-center">
              {isLoading && <Loading sidebar={false} />}
              {!isLoading && (
                <SetupTeamForm
                  branchName={state.branchName}
                  branchNumberId={state.branchId}
                  branchesInfo={branchesInfo}
                  employees={employees}
                  allGroups={allGroups}
                />
              )}
            </div>
          </div>
        </main>
      )}
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby={t("teams.group.create.title")}
          aria-describedby={t("teams.group.create.title")}
        >
          <Box sx={style}>
            <div className="bg-gray-light text-center text-xl font-normal text-black py-8 px-2">
              <h1>{t("teams.group.create.title")}</h1>
            </div>
            <form onSubmit={handleCreateGroup} className="p-8">
              <div className="grid px-6 gap-4">
                <TextField
                  type="text"
                  name="name"
                  label={t("teams.group.create.name")}
                  error={!!error}
                  helperText={error}
                  onChange={() => setError("")}
                  variant="standard"
                  sx={{
                    "& .MuiInput-root": {
                      color: "#000",
                      fontFamily: "Montserrat",
                      "&:before": {
                        borderBottom: "1px solid #838383",
                      },
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#898989",
                      },
                    },
                    "& label.Mui-focused": {
                      color: "#7e7e7e",
                    },
                    "& .MuiInputLabel-standard": {
                      color: "#000",
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#838383",
                    },
                  }}
                />
              </div>

              <div className="flex justify-center mt-12 mx-6">
                <Button
                  type="submit"
                  sx={{
                    padding: "4px 8px",
                    width: "100%",
                    background: "#EF0BB8",
                    borderRadius: "16px",
                    color: "white",
                    textTransform: "none",
                    fontFamily: "Montserrat",
                    fontWeight: 300,
                    "&.Mui-disabled": {
                      opacity: "40%",
                      color: "#fff",
                    },
                  }}
                >
                  {t("teams.group.create.submit")}
                </Button>
              </div>
            </form>
          </Box>
        </Modal>
      )}
    </div>
  );
}
