import { styled, TextField } from "@mui/material";
import React from "react";

const CustomDateInput = styled(TextField)(({ size }) => ({
  "& .MuiOutlinedInput-root": {
    padding: 0,
    fontFamily: "Montserrat",
    height: "2.5rem",
    fontSize: size === "medium" ? "inherit" : "12px",
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
  "& .MuiInputBase-input.MuiOutlinedInput-input": {
    padding: 0,
  },
}));

interface DateFilterProps {
  startDate: string;
  endDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  size?: "lg" | "sm";
}

export function DateFilter({
  endDate,
  setEndDate,
  setStartDate,
  startDate,
  size = "lg",
}: DateFilterProps) {
  return (
    <div
      className={`flex justify-evenly items-center ${
        size === "sm" ? "space-x-2" : "space-x-4"
      }`}
    >
      {/* Desde */}
      <div className="flex items-center">
        <span
          className={`text-[#898989] font-semibold ${
            size === "sm" ? "text-xs pr-1" : "text-sm"
          }`}
        >
          Desde
        </span>
        <div
          className={`flex items-center border border-gray-300 rounded-lg ${
            size === "sm" ? "px-1" : "px-2  ml-4"
          }`}
        >
          <CustomDateInput
            type="date"
            value={startDate}
            size={size === "sm" ? "small" : "medium"}
            onChange={(e: any) => setStartDate(e.target.value)}
            className="bg-white datePicker"
          />
        </div>
      </div>
      {/* Hasta */}
      <div className="flex items-center">
        <span
          className={`text-[#898989] font-semibold ${
            size === "sm" ? "text-xs pr-1" : "text-sm"
          }`}
        >
          Hasta
        </span>
        <div
          className={`flex items-center border border-gray-300 rounded-lg ${
            size === "sm" ? "px-1" : "px-2 ml-4"
          } `}
        >
          <CustomDateInput
            type="date"
            value={endDate}
            size={size === "sm" ? "small" : "medium"}
            onChange={(e: any) => setEndDate(e.target.value)}
            className="bg-white datePicker"
          />
        </div>
      </div>
    </div>
  );
}
